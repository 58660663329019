import { rem } from '@butcherbox/freezer'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import useProtectedPage from '~/cms/hooks/useProtectedPage'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import { cleanJsonStrings } from '~/utils/unicode'
import Ada from './Ada/Ada'
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy'
import TermsOfUse from './TermsOfUse/TermsOfUse'

const LegalPage = () => {
  const { pageData } = cleanJsonStrings(
    useStaticQuery<{ pageData: any }>(graphql`
      query {
        pageData: contentfulProtectedPage(pageId: { eq: "privacy-policy" }) {
          ...ProtectedPage
        }
      }
    `)
  )

  const { header } = useProtectedPage(pageData)

  return (
    <>
      <UnauthenticatedLayout
        className="legalPage"
        cta={header?.cta?.content}
        margin="auto"
        maxW={{ base: '100%', desktop: '120ch' }}
        px={rem(46)}
        py={rem(36)}
        title="Legal Page"
      >
        <section id="privacy-policy">
          <PrivacyPolicy />
        </section>
        <section id="terms-of-use">
          <TermsOfUse />
        </section>
        <section id="ada">
          <Ada />
        </section>
      </UnauthenticatedLayout>
    </>
  )
}

export default LegalPage
