import { Box, BoxProps } from '@chakra-ui/core'
import { rem } from 'design'
import {
  Body,
  H1,
  H2,
  LinkExternal,
} from 'design/components/Typography/Typography'
import React from 'react'
import '../Legal.css'

export default function AccessibilityStatement() {
  return (
    <>
      <H1 id="top">Accessibility Statement</H1>
      <Body>
        ButcherBox is committed to making its website usable by all people,
        including those with disabilities by meeting or exceeding the
        requirements of the Web Content Accessibility Guidelines 2.1 Level A/AA
        (WCAG 2.1 A/AA). We strive in making our site an equal experience for
        everyone.
      </Body>

      <Body>
        We continue to monitor our site and make sure that all content provided
        is accessible to all visitors. In order to help us in this process, we
        have engaged The Bureau of Internet Accessibility (BoIA.org), an
        accessibility consulting company, to conduct a full accessibility audit
        consisting of both automated and manual testing of our website using
        BoIA’s A11Y® platform which provides us with specific recommendations
        that will assist us in ensuring that our Web presence is in conformance
        with the WCAG 2.1 A/AA.
      </Body>

      <Section>
        <H2>Disclaimer</H2>

        <Body>
          It is important to note that efforts to the website are ongoing as we
          work to implement the relevant improvements to meet WCAG 2.1 A/AA
          guidelines over time.
        </Body>
      </Section>

      <Section>
        <H2>Live 24/7 Accessibility Support</H2>

        <Body>
          If you are having any issues relating to assistive technology,
          including screen readers, keyboard access, or other issues relating to
          the accessibility of our website, we want to help you achieve what you
          came to do. A live agent is standing by, ready to take your toll-free
          call and help resolve your issue together. Call (855) 408-8654 to talk
          now.
        </Body>

        <Body>
          If you prefer to provide feedback by email, please write to{' '}
          <EmailLink email="butcherbox@a11y.com" /> and be sure to specify the
          web page so we can look into making that page accessible for you. We
          greatly value your questions and comments. In the customer service
          section, we only want the phone number displayed in the Accessibility
          Statement, therefore the wording here changes as well:
        </Body>
      </Section>

      <Section>
        <H2>Third Party Applications</H2>

        <Body>
          While you are visiting our site, you will notice that we make use of
          third-party sites such as, YouTube, Facebook, Instagram, Twitter, and
          Pinterest to provide information about ButcherBox. Though individuals
          may have challenges with access to these particular sites, ButcherBox
          does not control or remedy the way content is portrayed.
        </Body>
      </Section>
    </>
  )
}

const EmailLink = ({ email, ...props }) => (
  <LinkExternal href={`mailto:${email}`} target="_self" {...props}>
    {email}
  </LinkExternal>
)

const Section = (props: BoxProps) => (
  <Box as="section" css={{ '> *': { marginBottom: rem(24) } }} {...props} />
)
