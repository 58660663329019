import { H3 } from 'design/components/Typography/Typography'
import { get } from 'lodash'
import React from 'react'
import { CMS } from '~/cms/types'
import { useAcquisitonBannerContent } from '~/hooks/useBannerContent'

type Options = {
  useCheckoutSettings?: boolean
  setAcquisitionBanner?: boolean
  shouldReplaceHeader?: boolean
}

const optionDefaults: Options = {
  useCheckoutSettings: false,
  setAcquisitionBanner: true,
  shouldReplaceHeader: true,
}

/**
 * useProtectedPage extracts data from a Contentful Protected Page entry and makes it available to use in components.
 *
 * When called without a parser this hook is unable to determine the expected shape of the `content` object
 */
function useProtectedPage(
  pageData?: CMS.ProtectedPage<CMS.ProductOfferLander>,
  parser?: null,
  options?: Options
): {
  content: CMS.ProtectedPage<CMS.ProductOfferLander>['content']
  header: CMS.ProtectedPage<CMS.ProductOfferLander>['header']
  seo: CMS.ProtectedPage<CMS.ProductOfferLander>['seo']
}
/**
 * useProtectedPage extracts data from a Contentful Protected Page entry and makes it available to use in components.
 *
 * When called with a parser this hook will extract data from the provided parser
 */
function useProtectedPage<T extends (...args: any[]) => any>(
  pageData?: CMS.ProtectedPage<CMS.ProductOfferLander>,
  parser?: T,
  options?: Options
): {
  content: ReturnType<T>
  header: CMS.ProtectedPage<CMS.ProductOfferLander>['header']
  seo: CMS.ProtectedPage<CMS.ProductOfferLander>['seo']
}
function useProtectedPage(
  pageData?: CMS.ProtectedPage<CMS.ProductOfferLander>,
  parser?: any,
  options: Options = optionDefaults
): {
  content: any
  header: CMS.ProtectedPage<CMS.ProductOfferLander>['header']
  seo: CMS.ProtectedPage<CMS.ProductOfferLander>['seo']
} {
  const { content, setContent } = useAcquisitonBannerContent()

  if (!pageData) {
    throw new Error('Essential page data is missing')
  }

  React.useEffect(() => {
    //setAcquisitionBanner set to true on the homepage
    //should we be checking to see if a banner is already set?
    if (options?.setAcquisitionBanner === false) {
      return
    } else if (
      content !== undefined &&
      options?.shouldReplaceHeader === false
    ) {
      return
    } else {
      setContent(
        pageData?.header?.banner?.content ? (
          <H3 as="p" data-what="banner-text">
            {pageData?.header?.banner?.content}
          </H3>
        ) : null,
        { useCheckoutSettings: options?.useCheckoutSettings }
      )
    }
    // setContent is static and should not be in deps array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setContent,
    pageData?.header?.banner?.content,
    options?.setAcquisitionBanner,
    options?.useCheckoutSettings,
  ])

  // TODO: Set SEO from this hook. See WEB-1182.
  const seoImage = get(pageData, 'seo.image.fixed.srcWebp', null)

  return {
    content:
      typeof parser !== 'function'
        ? pageData.content
        : parser(pageData?.content),
    header: pageData?.header,
    seo: { __typename: 'ContentfulSeo', ...pageData?.seo, image: seoImage },
  }
}

export default useProtectedPage
